body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #000;
    overflow-x: hidden;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
  
  * {
    outline: none !important;
  }

  .App {
      overflow: hidden !important;
  }

  @mixin breakpoint($class) {
    @if $class == xs {
      @media (max-width: 767px) { @content; }
    }
  
    @else if $class == sm {
      @media (max-width: 768px) { @content; }
    }
  
    @else if $class == md {
      @media (min-width: 992px) { @content; }
    }
  
    @else if $class == lg {
      @media (min-width: 1200px) { @content; }
    }
  
    @else if $class == xl {
      @media (max-width: 1500px) { @content; }
    }
  
    @else {
      @warn "Breakpoint mixin supports: xs, sm, md, lg";
    }
  }
  
  
  .head__bar {
    width: 100%;
    background: #BA7D08;
    padding: 15px;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .bridge__container {
    width: 1000px;
    margin: 0 auto;
    padding-bottom: 50px;
    box-sizing: border-box;

    @include breakpoint(sm) {
        width: 100%;
        padding: 17px;
    }
  
    h1 {
      font-size: 50px;
      text-align: center;
      color: #fff;
      margin-top: 60px;
      margin-bottom: 0;

      @include breakpoint(sm) {
        font-size: 40px;
        margin-top: 30px;
      }
    }
  
    p {
      text-align: center;
      font-size: 30px;
      color: #fff;
      margin-top: 40px;
      width: 70%;
      text-align: center;
      margin: 0px auto;
      padding-top: 30px;
      font-weight: 600;

      @include breakpoint(sm) {
        font-size: 25px;
        width: 100%;
      }
    }
  
    h1 span {
      background: rgb(240, 38, 38);
      padding-left: 3px;
      padding-right: 3px;
    }
  
    p span {
      color: rgb(240, 38, 38);
    }
  
    img {
      width: 1000px;
      margin-top: 40px;
      border: 5px solid #FFBB02;

      @include breakpoint(sm) {
        width: 100%;
        margin-left: -5px;
      }
    }
  
    .form {
      display: flex;
      flex-wrap: wrap;
      padding-top: 25px;
    }
  
    input {
      width: 100%;
      width: 100%;
      padding: 10px;
      height: 40px;
      font-size: 18px;
    }
  
    a {
      color: #fff;
      margin-top: 20px;
      width: 100%;
      text-align: center;
      background: red;
      padding: 10px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      font-weight: 900;
      font-size: 20px;
      cursor: pointer;
      text-decoration: none;
  
      &:hover {
        opacity: 0.8;
      }
    }
  
    .error {
      color: red;
      padding: 10px 0 0 0;
    }
  
    video {
      margin-top: 40px;

      @include breakpoint(sm) {
        width: 100%;
        margin-top: 0;
      }
    }
  }